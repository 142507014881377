body {
    background-color: rgb(248, 245, 241);
}

/**
 * Header Section
 **/
.em-header {
    background-color: rgb(1, 98, 98);
    width: 100%;
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
}

/**
 * Footer Section
 **/
.em-footer {
    background-color: rgb(3, 139, 139);
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 70px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 10px;
    text-align: center;
    color: antiquewhite;
}

.scroll-invisible {
    display: none;
}

.scroll-to-top {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: transparent;
    color: rgb(1, 98, 98);
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px rgb(1, 98, 98);
    font-size: x-large;
}

.scroll-to-top:hover {
    cursor: pointer;
}

.scroll-to-top-inner {
    margin-top: 18px;
}

/**
 * Navigation Section
 **/
.navbar-dark {
    font-size: large;
    color: honeydew;
}
/**
 * Featured Items
 **/

.featured-items {
    padding-top: 60px;
    padding-bottom: 0;
    background-color: rgb(82, 148, 148);
}

.fav-img {
    height: 200px;
    width: 300px;
}

@media only screen and (max-width: 768px) {
    .featured-item {
        margin-bottom: 20px;
    }
}

.fav-link:hover {
    text-decoration: none;
}

.card-body {
    background-color: rgb(248, 245, 241);
}

.card-body:hover {
    text-decoration: none;
}

.card-left {
    width: 50%;
    background-color: rgb(236, 241, 245);
}

.card-right {
    padding: 5px 10px;
    width: 45%;
    color: black;
}

.card-right:hover {
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .card-left {
        width: 100%;
    }
    .card-right {
        width: 100%;
    }
}

.btn-featured {
    margin-top: 10px;
    background-color: rgb(4, 75, 51);
}

.btn-featured:hover {
    background-color: rgb(2, 1, 54);
}

.btn-featured:focus {
    background-color: rgb(4, 75, 51);
}

/**
 * Left Navbar
 **/

.navbar-toggler {
    background-color: rgb(1, 98, 98);
    color: antiquewhite;
}

.navbar-toggler-icon {
    color: antiquewhite;
}

.nav-left {
    width: 100%;
    margin-top: 10px;
}

.nav-item-left {
    width: 100%;
    margin-bottom: 10px;
}

.nav-link-left {
    width: 100%;
    font-weight: bold;
    background-color: rgb(4, 75, 51);
    color: antiquewhite;
}

.nav-link-left:hover {
    background-color: rgb(2, 1, 54);
    color: antiquewhite;
}

.nav-link-left:focus {
    background-color: rgb(4, 75, 51);
    color: antiquewhite;
}

.nav-link-left:active {
    background-color: brown;
}

/**
 * Main Body
 **/
.main-body {
    position: relative;
    min-height: 500px;
}

.left-panel {
    position: absolute;
    width: 25%;
    background-color: rgb(240, 248, 248);
    z-index: 1;
    overflow-x: hidden;
}

.right-panel {
    position: relative;
    width: 75%;
    margin-left: 25%;
}

@media only screen and (max-width: 768px) {
    .left-panel {
        position: relative;
        width: 100%;
    }

    .right-panel {
        position: relative;
        width: 100%;
        margin-left: 0;
    }

    .right-panel-inner {
        padding-top: 10px;
    }
}

.not-found {
    color: rgb(255, 1, 1);
    text-align: center;
}

div.spinner {
    text-align: center;
}

.spinner-border {
    color: rgb(1, 98, 98);
    width: 5rem;
    height: 5rem;
}

div.loading-error {
    color: rgb(200, 4, 4);
    text-align: center;
}

/**
 * Render Posts Component
 **/

.item-card {
    position: relative;
    min-height: 150px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: rgb(235, 240, 239);
}

.item-card-img {
    position: relative;
    height: 200px;
    text-align: center;
    border: double 2px rgb(5, 0, 0);
}

.item-card-body {
    position: relative;
    padding: 10px 15px;
}

.item-card-sub {
    border-left: double 1px rgb(1, 98, 98);
    position: relative;
    text-align: center;
}

.item-card-img img {
    height: 196px;
    width: 280px;
}

@media only screen and (max-width: 1280px) {
    .item-card-sub {
        border-left: none;
        border-top: double 1px rgb(1, 98, 98);
    }
}

/**
 * Single Article
 **/

.single-article-img {
    position: relative;
    text-align: center;
    border: solid 1px rgb(1, 98, 98);
    background-color: antiquewhite;
}

.single-article-title {
    position: relative;
    text-align: center;
}

.single-article-content {
    position: relative;
    margin: 5px 5px;
}

.article-img {
    position: relative;
    height: 200px;
    width: 300px;
    text-align: center;
}

.btn-update {
    background-color: rgb(1, 98, 98);
    color: antiquewhite;
}

/**
 * Favourite Items
 **/
.fav-h {
    text-decoration: none;
}

/**
 * Comments
 **/
.card-comment {
    margin: 5px auto;
}

.card-body-comment {
    background-color: rgb(248, 245, 242);
}

.card-footer-comment {
    background-color: antiquewhite;
}
.btn-comment-disapprove {
    background-color: rgb(151, 8, 8);
}
.btn-comment-approve {
    background-color: rgb(168, 113, 11);
}
.btn-comment-remove {
    background-color: rgb(199, 5, 5);
}

.btn-comment:hover {
    background-color: darkolivegreen;
}

.modal-comment {
    background-color: antiquewhite;
}

.modal-header-comment {
    background-color: antiquewhite;
}

.modal-footer-comment {
    background-color: antiquewhite;
}

.btn-modal-comment-dismiss {
    background-color: red;
}

.btn-modal-comment-submit {
    background-color: green;
}

.btn-add-comment {
    background-color: cadetblue;
}
